'use client'

import { HeartIcon } from '@heroicons/react/24/solid'
import { cn } from '@sq/components'
import { Supply } from '@sq/data/schemas'
import { formatPrice, getSupplyArea, getSupplyTotalPrice } from '@sq/data/utils'
import Image from 'next/image'
import Link from 'next/link'
import lastuseIcon from 'public/lastuse-icon.svg'
import rulerIcon from 'public/ruler-icon.svg'
import React, { useCallback, useMemo } from 'react'
import { useFavoriteSupply } from 'src/hooks/supply'

import { CardTrackClickType, EventType, GetCardEventProperties, track } from '@/components/tracks'
import {
    getSupplyLastUse,
    getSupplyTypeDetail,
    toAddressLine1Supply,
    toAddressLine2Supply,
} from '@/lib/@core/supply/supply.utils'

import SupplyCardCarousel from './SupplyCardCarousel'

type Props = {
    supply: Supply
    trackOrigin?: CardTrackClickType
    disableCarousel?: boolean
    variant?: 'fixedWidth' | 'fullWidth' | 'insideMap'
}

const tagList = ['tag_esquina', 'tag_em_alta', 'tag_reformado', 'tag_preco_baixo', 'tag_new_supply'] as const

const tagI18nMap = {
    tag_esquina: 'Esquina',
    tag_em_alta: 'Em alta',
    tag_reformado: 'Reformado',
    tag_preco_baixo: 'Preço baixo',
    tag_new_supply: 'Anúncio novo',
} as const

function SupplyCard({ supply, variant = 'fixedWidth', disableCarousel, trackOrigin }: Props) {
    const { isFavorite, toggleFavorite } = useFavoriteSupply(supply)
    const addressLine1 = toAddressLine1Supply(supply)

    const trackSupplyClick = useCallback(() => {
        track(EventType.CardClick, {
            ...GetCardEventProperties(`Supply - ${addressLine1}`, trackOrigin ?? CardTrackClickType.PropertyCard),
            supply,
        })
    }, [supply, addressLine1, trackOrigin])

    const trackCarouselPaginate = useCallback(() => {
        track(EventType.CardClick, {
            ...GetCardEventProperties(
                `Supply - Carousel Paginate - ${addressLine1}`,
                CardTrackClickType.PropertyCardCarouselPaginate
            ),
            supply,
        })
    }, [supply, addressLine1])

    const onClickCarousel = () => {
        trackSupplyClick()
        const url = `${window.location.origin}/imovel/${supply.slug}`
        window.open(url, '_blank')
    }

    const tags = useMemo(() => {
        return tagList
            .filter((tag) => {
                if (tag === 'tag_esquina' && supply.details['Esquina']?.value === 'Sim') return true
                if (tag === 'tag_new_supply' && supply.published_at !== '') {
                    const inputDate = new Date(supply.published_at)
                    const thirtyDaysInMillis = 30 * 24 * 60 * 60 * 1000
                    const timeDifference = Date.now() - inputDate.getTime()

                    return timeDifference <= thirtyDaysInMillis
                }
                return supply.details[tag] && supply.details[tag].value !== '0' && supply.details[tag].value !== ''
            })
            .slice(0, 2)
            .map((tag) => tagI18nMap[tag])
    }, [supply])

    const lastUse = getSupplyLastUse(supply)

    return (
        <div
            className={cn(
                'overflow-hidden rounded-2xl border border-sq-gray-200 transition-shadow duration-300 animate-in fade-in pointer-fine:hover:shadow-lg',
                variant === 'fixedWidth' && 'min-w-[288px] max-w-[288px] md:min-h-[336px]',
                variant === 'fullWidth' && 'w-full min-w-[288px] md:min-h-[362px]',
                variant === 'insideMap' && 'h-[320px] w-[264px]'
            )}
        >
            <div className="group relative aspect-[1.53] w-full overflow-hidden">
                <div className="absolute z-10 flex w-full flex-row items-center justify-between gap-[10px] px-3 pt-3">
                    <div className="flex items-start justify-start gap-2.5">
                        {tags.map((tag) => (
                            <span
                                key={tag}
                                className="flex h-[26px] items-center justify-center gap-2.5 rounded-2xl bg-white px-3 py-0.5 text-xs font-bold text-sq-gray-900"
                            >
                                {tag}
                            </span>
                        ))}
                    </div>

                    <HeartIcon
                        className={cn(
                            'w-[26px] stroke-white stroke-2 transition-all duration-300 hover:cursor-pointer',
                            isFavorite ? 'fill-sq-red-500' : 'fill-[#00000080]'
                        )}
                        onClick={toggleFavorite}
                    />
                </div>

                <SupplyCardCarousel
                    images={disableCarousel ? supply.images.slice(0, 1) : supply.images}
                    onClick={onClickCarousel}
                    onPaginate={trackCarouselPaginate}
                />
            </div>
            <Link
                href={`/imovel/${supply.slug}`}
                target="_blank"
                className="flex flex-col gap-0.5 p-4"
                onClick={trackSupplyClick}
            >
                <span
                    className={cn(
                        'text-ellipsis text-sm font-medium text-sq-gray-900',
                        variant === 'insideMap' ? 'line-clamp-1' : 'line-clamp-2'
                    )}
                >
                    {addressLine1}
                </span>
                <span className="text-sm text-sq-gray-900">{toAddressLine2Supply(supply)}</span>
                <span className="text-sm font-bold text-sq-gray-900">
                    Valor total: {formatPrice(getSupplyTotalPrice(supply))}
                </span>
                <div className="flex flex-shrink-0 flex-row flex-wrap gap-x-4 gap-y-2">
                    <div className="flex items-center gap-1.5 py-1">
                        <Image src={rulerIcon} alt="Ruler Icon" width={16} height={16} />
                        <span className="text-xs font-medium text-sq-gray-900">
                            {getSupplyArea(supply)} m<sup>2</sup>
                        </span>
                    </div>

                    {lastUse && variant !== 'insideMap' && (
                        <div className="py-1 flex items-center gap-[6px]">
                            <Image src={lastuseIcon} alt="People Icon" width={16} height={16} />
                            <span className="text-xs text-sq-gray-900">Antes era: {lastUse}</span>
                        </div>
                    )}
                </div>
                <div className="flex h-6 w-fit items-center rounded-[4px] bg-[#E9ECEF] px-2 py-1 text-xs font-medium">
                    {getSupplyTypeDetail(supply)}
                </div>
            </Link>
        </div>
    )
}

export default SupplyCard
